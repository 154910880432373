/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStatusEntity } from './projectStatusEntity';
import { ProjectPurchaseDataEntity } from './projectPurchaseDataEntity';


export interface ProjectPurchaseEntity { 
    data: ProjectPurchaseDataEntity;
    _id: string;
    code?: string;
    status: ProjectStatusEntity;
    type: ProjectPurchaseEntity.TypeEnum;
    channel?: number;
}
export namespace ProjectPurchaseEntity {
    export type TypeEnum = 'vendita' | 'acquisto';
    export const TypeEnum = {
        Vendita: 'vendita' as TypeEnum,
        Acquisto: 'acquisto' as TypeEnum
    };
}


