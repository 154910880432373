/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PropertyAreasDescriptionEntity } from './propertyAreasDescriptionEntity';
import { CadastralDataEntity } from './cadastralDataEntity';
import { ExposureEntity } from './exposureEntity';
import { AddressEntity } from './addressEntity';


export interface PropertyDataEntity { 
    priceOnRequest?: PropertyDataEntity.PriceOnRequestEnum;
    hasCondominiumFees?: PropertyDataEntity.HasCondominiumFeesEnum;
    hasHeatingCosts?: PropertyDataEntity.HasHeatingCostsEnum;
    isFree?: PropertyDataEntity.IsFreeEnum;
    rentWithRedemption?: PropertyDataEntity.RentWithRedemptionEnum;
    toIncome?: PropertyDataEntity.ToIncomeEnum;
    balcony?: PropertyDataEntity.BalconyEnum;
    terrace?: PropertyDataEntity.TerraceEnum;
    builtInWardrobes?: PropertyDataEntity.BuiltInWardrobesEnum;
    cellar?: PropertyDataEntity.CellarEnum;
    attic?: PropertyDataEntity.AtticEnum;
    tavern?: PropertyDataEntity.TavernEnum;
    securityDoor?: PropertyDataEntity.SecurityDoorEnum;
    alarm?: PropertyDataEntity.AlarmEnum;
    electricGate?: PropertyDataEntity.ElectricGateEnum;
    videoIntercom?: PropertyDataEntity.VideoIntercomEnum;
    opticFiber?: PropertyDataEntity.OpticFiberEnum;
    chimney?: PropertyDataEntity.ChimneyEnum;
    hydromassage?: PropertyDataEntity.HydromassageEnum;
    pool?: PropertyDataEntity.PoolEnum;
    tennisCourt?: PropertyDataEntity.TennisCourtEnum;
    onSeveralLevels?: PropertyDataEntity.OnSeveralLevelsEnum;
    lift?: PropertyDataEntity.LiftEnum;
    disabledNeeds?: PropertyDataEntity.DisabledNeedsEnum;
    cadastralData?: CadastralDataEntity;
    images?: Array<string>;
    address?: AddressEntity;
    propertyTypology?: number;
    budget?: number;
    propertyType?: number;
    sellingPrice?: number;
    condominiumFees?: number;
    heatingCosts?: number;
    cadastralCategory?: number;
    cadastralIncome?: number;
    squareMeters?: number;
    saleProject?: string;
    saleReason?: string;
    bedroomsNumber?: number;
    otherRooms?: number;
    totalRooms?: number;
    kitchen?: number;
    kitchenQuality?: number;
    furniture?: number;
    bathroomsNumber?: number;
    garden?: number;
    gardenSquareMeters?: number;
    garage?: number;
    garageSquareMeters?: number;
    boxNumber?: number;
    balconySquareMeters?: number;
    externalFixtures?: number;
    tvSystem?: number;
    conciergeService?: number;
    buildYear?: number;
    propertyClass?: number;
    propertyState?: number;
    floor?: number;
    totalBuildingFloors?: number;
    freeSides?: number;
    overlooking?: number;
    heating?: number;
    heatingPlantType?: number;
    supply?: number;
    airConditioning?: number;
    airConditioningPlantType?: number;
    energyClass?: number;
    finishesQuality?: number;
    plantQuality?: number;
    description?: string;
    propertyIdCrm?: string;
    propertyIdEc?: string;
    operatorEcId?: string;
    areasDescription?: Array<PropertyAreasDescriptionEntity>;
    extraAreasDescription?: Array<PropertyAreasDescriptionEntity>;
    extraSquareMeters?: number;
    totSquareMeters?: number;
    commercialIncidence?: number;
    extraCommercialIncidence?: number;
    evaluatedSquareMeters?: number;
    exposure?: ExposureEntity;
}
export namespace PropertyDataEntity {
    export type PriceOnRequestEnum = 'nd' | 'true' | 'false';
    export const PriceOnRequestEnum = {
        Nd: 'nd' as PriceOnRequestEnum,
        True: 'true' as PriceOnRequestEnum,
        False: 'false' as PriceOnRequestEnum
    };
    export type HasCondominiumFeesEnum = 'nd' | 'true' | 'false';
    export const HasCondominiumFeesEnum = {
        Nd: 'nd' as HasCondominiumFeesEnum,
        True: 'true' as HasCondominiumFeesEnum,
        False: 'false' as HasCondominiumFeesEnum
    };
    export type HasHeatingCostsEnum = 'nd' | 'true' | 'false';
    export const HasHeatingCostsEnum = {
        Nd: 'nd' as HasHeatingCostsEnum,
        True: 'true' as HasHeatingCostsEnum,
        False: 'false' as HasHeatingCostsEnum
    };
    export type IsFreeEnum = 'nd' | 'true' | 'false';
    export const IsFreeEnum = {
        Nd: 'nd' as IsFreeEnum,
        True: 'true' as IsFreeEnum,
        False: 'false' as IsFreeEnum
    };
    export type RentWithRedemptionEnum = 'nd' | 'true' | 'false';
    export const RentWithRedemptionEnum = {
        Nd: 'nd' as RentWithRedemptionEnum,
        True: 'true' as RentWithRedemptionEnum,
        False: 'false' as RentWithRedemptionEnum
    };
    export type ToIncomeEnum = 'nd' | 'true' | 'false';
    export const ToIncomeEnum = {
        Nd: 'nd' as ToIncomeEnum,
        True: 'true' as ToIncomeEnum,
        False: 'false' as ToIncomeEnum
    };
    export type BalconyEnum = 'nd' | 'true' | 'false';
    export const BalconyEnum = {
        Nd: 'nd' as BalconyEnum,
        True: 'true' as BalconyEnum,
        False: 'false' as BalconyEnum
    };
    export type TerraceEnum = 'nd' | 'true' | 'false';
    export const TerraceEnum = {
        Nd: 'nd' as TerraceEnum,
        True: 'true' as TerraceEnum,
        False: 'false' as TerraceEnum
    };
    export type BuiltInWardrobesEnum = 'nd' | 'true' | 'false';
    export const BuiltInWardrobesEnum = {
        Nd: 'nd' as BuiltInWardrobesEnum,
        True: 'true' as BuiltInWardrobesEnum,
        False: 'false' as BuiltInWardrobesEnum
    };
    export type CellarEnum = 'nd' | 'true' | 'false';
    export const CellarEnum = {
        Nd: 'nd' as CellarEnum,
        True: 'true' as CellarEnum,
        False: 'false' as CellarEnum
    };
    export type AtticEnum = 'nd' | 'true' | 'false';
    export const AtticEnum = {
        Nd: 'nd' as AtticEnum,
        True: 'true' as AtticEnum,
        False: 'false' as AtticEnum
    };
    export type TavernEnum = 'nd' | 'true' | 'false';
    export const TavernEnum = {
        Nd: 'nd' as TavernEnum,
        True: 'true' as TavernEnum,
        False: 'false' as TavernEnum
    };
    export type SecurityDoorEnum = 'nd' | 'true' | 'false';
    export const SecurityDoorEnum = {
        Nd: 'nd' as SecurityDoorEnum,
        True: 'true' as SecurityDoorEnum,
        False: 'false' as SecurityDoorEnum
    };
    export type AlarmEnum = 'nd' | 'true' | 'false';
    export const AlarmEnum = {
        Nd: 'nd' as AlarmEnum,
        True: 'true' as AlarmEnum,
        False: 'false' as AlarmEnum
    };
    export type ElectricGateEnum = 'nd' | 'true' | 'false';
    export const ElectricGateEnum = {
        Nd: 'nd' as ElectricGateEnum,
        True: 'true' as ElectricGateEnum,
        False: 'false' as ElectricGateEnum
    };
    export type VideoIntercomEnum = 'nd' | 'true' | 'false';
    export const VideoIntercomEnum = {
        Nd: 'nd' as VideoIntercomEnum,
        True: 'true' as VideoIntercomEnum,
        False: 'false' as VideoIntercomEnum
    };
    export type OpticFiberEnum = 'nd' | 'true' | 'false';
    export const OpticFiberEnum = {
        Nd: 'nd' as OpticFiberEnum,
        True: 'true' as OpticFiberEnum,
        False: 'false' as OpticFiberEnum
    };
    export type ChimneyEnum = 'nd' | 'true' | 'false';
    export const ChimneyEnum = {
        Nd: 'nd' as ChimneyEnum,
        True: 'true' as ChimneyEnum,
        False: 'false' as ChimneyEnum
    };
    export type HydromassageEnum = 'nd' | 'true' | 'false';
    export const HydromassageEnum = {
        Nd: 'nd' as HydromassageEnum,
        True: 'true' as HydromassageEnum,
        False: 'false' as HydromassageEnum
    };
    export type PoolEnum = 'nd' | 'true' | 'false';
    export const PoolEnum = {
        Nd: 'nd' as PoolEnum,
        True: 'true' as PoolEnum,
        False: 'false' as PoolEnum
    };
    export type TennisCourtEnum = 'nd' | 'true' | 'false';
    export const TennisCourtEnum = {
        Nd: 'nd' as TennisCourtEnum,
        True: 'true' as TennisCourtEnum,
        False: 'false' as TennisCourtEnum
    };
    export type OnSeveralLevelsEnum = 'nd' | 'true' | 'false';
    export const OnSeveralLevelsEnum = {
        Nd: 'nd' as OnSeveralLevelsEnum,
        True: 'true' as OnSeveralLevelsEnum,
        False: 'false' as OnSeveralLevelsEnum
    };
    export type LiftEnum = 'nd' | 'true' | 'false';
    export const LiftEnum = {
        Nd: 'nd' as LiftEnum,
        True: 'true' as LiftEnum,
        False: 'false' as LiftEnum
    };
    export type DisabledNeedsEnum = 'nd' | 'true' | 'false';
    export const DisabledNeedsEnum = {
        Nd: 'nd' as DisabledNeedsEnum,
        True: 'true' as DisabledNeedsEnum,
        False: 'false' as DisabledNeedsEnum
    };
}


