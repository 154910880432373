/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStatusEntity } from './projectStatusEntity';


export interface ProjectFindByIdResponseDto { 
    data: object;
    _id: string;
    code?: string;
    status: ProjectStatusEntity;
    type: ProjectFindByIdResponseDto.TypeEnum;
    channel?: number;
}
export namespace ProjectFindByIdResponseDto {
    export type TypeEnum = 'vendita' | 'acquisto';
    export const TypeEnum = {
        Vendita: 'vendita' as TypeEnum,
        Acquisto: 'acquisto' as TypeEnum
    };
}


