/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventRangeDto } from './eventRangeDto';
import { NumberRangeDto } from './numberRangeDto';


export interface ProjectSearchDTO { 
    ids?: Array<string>;
    firstName?: string;
    lastName?: string;
    nominative?: string;
    agentId?: string;
    insertDateRange?: EventRangeDto;
    createdDateRange?: EventRangeDto;
    opportunityInsertDateRange?: EventRangeDto;
    operatorId?: string;
    phone?: string;
    email?: string;
    address?: string;
    province?: string;
    sources?: Array<number>;
    sourceType?: number;
    leadStatus?: Array<ProjectSearchDTO.LeadStatusEnum>;
    opportunityStatus?: Array<ProjectSearchDTO.OpportunityStatusEnum>;
    projectType?: string;
    propertyCode?: string;
    propertyTypology?: string;
    network?: string;
    utmCampaign?: string;
    utmSource?: string;
    source?: number;
    propertyMode?: boolean;
    assignmentExpirationDateRange?: EventRangeDto;
    publishedIn?: string;
    contactId?: string;
    finalCalculatedPropertyMqRange?: NumberRangeDto;
    finalCalculatedPropertyPriceRange?: NumberRangeDto;
    finalCalculatedPropertyPriceForMqRange?: NumberRangeDto;
    sellingPriceRange?: NumberRangeDto;
    mqSellingPriceRange?: NumberRangeDto;
    deviationRange?: NumberRangeDto;
    deviationPercRange?: NumberRangeDto;
    calculatedCommissionRange?: NumberRangeDto;
    opportunityCreatorId?: string;
    preferredAddress?: string;
    preferredProvince?: string;
    preferredMqRange?: NumberRangeDto;
    preferredRooms?: NumberRangeDto;
    preferredBudget?: NumberRangeDto;
    preferredStructureState?: number;
    callcenterUserAssign?: string;
    casabookState?: string;
    marketingFlag?: boolean;
    commercialFlag?: boolean;
    profileFlag?: boolean;
    otherSubFlag?: boolean;
    priceAdjustmentsMode?: boolean;
    site?: string;
    area?: number;
    timeSlot?: string;
    propertyTotalRoomsRange?: NumberRangeDto;
    propertyState?: number;
    hasOpenhouse?: boolean;
    isUnavailable?: boolean;
    visitEventsCountRange?: NumberRangeDto;
    daysForSaleRange?: NumberRangeDto;
    lastLeadChangeDate?: EventRangeDto;
    lastOpportunityChangeDate?: EventRangeDto;
}
export namespace ProjectSearchDTO {
    export type LeadStatusEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58;
    export const LeadStatusEnum = {
        NUMBER_1: 1 as LeadStatusEnum,
        NUMBER_2: 2 as LeadStatusEnum,
        NUMBER_3: 3 as LeadStatusEnum,
        NUMBER_4: 4 as LeadStatusEnum,
        NUMBER_5: 5 as LeadStatusEnum,
        NUMBER_6: 6 as LeadStatusEnum,
        NUMBER_7: 7 as LeadStatusEnum,
        NUMBER_50: 50 as LeadStatusEnum,
        NUMBER_51: 51 as LeadStatusEnum,
        NUMBER_52: 52 as LeadStatusEnum,
        NUMBER_53: 53 as LeadStatusEnum,
        NUMBER_54: 54 as LeadStatusEnum,
        NUMBER_55: 55 as LeadStatusEnum,
        NUMBER_56: 56 as LeadStatusEnum,
        NUMBER_57: 57 as LeadStatusEnum,
        NUMBER_58: 58 as LeadStatusEnum
    };
    export type OpportunityStatusEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14;
    export const OpportunityStatusEnum = {
        NUMBER_1: 1 as OpportunityStatusEnum,
        NUMBER_2: 2 as OpportunityStatusEnum,
        NUMBER_3: 3 as OpportunityStatusEnum,
        NUMBER_4: 4 as OpportunityStatusEnum,
        NUMBER_5: 5 as OpportunityStatusEnum,
        NUMBER_6: 6 as OpportunityStatusEnum,
        NUMBER_7: 7 as OpportunityStatusEnum,
        NUMBER_8: 8 as OpportunityStatusEnum,
        NUMBER_9: 9 as OpportunityStatusEnum,
        NUMBER_10: 10 as OpportunityStatusEnum,
        NUMBER_11: 11 as OpportunityStatusEnum,
        NUMBER_12: 12 as OpportunityStatusEnum,
        NUMBER_13: 13 as OpportunityStatusEnum,
        NUMBER_14: 14 as OpportunityStatusEnum
    };
}


