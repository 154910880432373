/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BCProfitEntity { 
    agent: string;
    amount: number;
    percent: number;
    type: BCProfitEntity.TypeEnum;
}
export namespace BCProfitEntity {
    export type TypeEnum = 'vendita' | 'acquisto';
    export const TypeEnum = {
        Vendita: 'vendita' as TypeEnum,
        Acquisto: 'acquisto' as TypeEnum
    };
}


