/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AnagraphicCasabookEntity } from './anagraphicCasabookEntity';
import { NoteCasabookEntity } from './noteCasabookEntity';
import { AddressEntity } from './addressEntity';


export interface DataCasabookEntity { 
    address: AddressEntity;
    deliveryAddress: AddressEntity;
    buildingType: string;
    rooms: number;
    bedrooms: number;
    bathrooms: number;
    floor: string;
    description: string;
    mq: number;
    price: number;
    cadastalCategory: string;
    garden: boolean;
    garage: boolean;
    parkingLot: boolean;
    balcony: boolean;
    terrace: boolean;
    hasToChangeFrames: boolean;
    heatingType: DataCasabookEntity.HeatingTypeEnum;
    airConditioner: boolean;
    agency: AnagraphicCasabookEntity;
    designer: AnagraphicCasabookEntity;
    agent: AnagraphicCasabookEntity;
    teamManager: AnagraphicCasabookEntity;
    needElectricWorks: boolean;
    needHydraulicWorks: boolean;
    needTermicWorks: boolean;
    notes: Array<NoteCasabookEntity>;
    state: DataCasabookEntity.StateEnum;
}
export namespace DataCasabookEntity {
    export type HeatingTypeEnum = 'Assente' | 'Autonomo' | 'Condominiale';
    export const HeatingTypeEnum = {
        Assente: 'Assente' as HeatingTypeEnum,
        Autonomo: 'Autonomo' as HeatingTypeEnum,
        Condominiale: 'Condominiale' as HeatingTypeEnum
    };
    export type StateEnum = 'Richiesto' | 'In lavorazione' | 'Completo';
    export const StateEnum = {
        Richiesto: 'Richiesto' as StateEnum,
        InLavorazione: 'In lavorazione' as StateEnum,
        Completo: 'Completo' as StateEnum
    };
}


