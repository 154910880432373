/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateOpportunityRequestDto { 
    projectId: string;
    newState: CreateOpportunityRequestDto.NewStateEnum;
}
export namespace CreateOpportunityRequestDto {
    export type NewStateEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
    export const NewStateEnum = {
        NUMBER_1: 1 as NewStateEnum,
        NUMBER_2: 2 as NewStateEnum,
        NUMBER_3: 3 as NewStateEnum,
        NUMBER_4: 4 as NewStateEnum,
        NUMBER_5: 5 as NewStateEnum,
        NUMBER_6: 6 as NewStateEnum,
        NUMBER_7: 7 as NewStateEnum,
        NUMBER_8: 8 as NewStateEnum,
        NUMBER_9: 9 as NewStateEnum,
        NUMBER_10: 10 as NewStateEnum,
        NUMBER_11: 11 as NewStateEnum
    };
}


