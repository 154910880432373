/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStatusEntity } from './projectStatusEntity';
import { ProjectSale } from './projectSale';
import { ProjectEntity } from './projectEntity';
import { ProjectSaleDataEntity } from './projectSaleDataEntity';


export interface ProjectSaleEntity1Inner { 
    _id: string;
    code?: string;
    status: ProjectStatusEntity;
    type: ProjectSaleEntity1Inner.TypeEnum;
    data: ProjectSaleDataEntity;
    channel?: number;
}
export namespace ProjectSaleEntity1Inner {
    export type TypeEnum = 'vendita' | 'acquisto';
    export const TypeEnum = {
        Vendita: 'vendita' as TypeEnum,
        Acquisto: 'acquisto' as TypeEnum
    };
}


