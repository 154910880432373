/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DisputeEventChangeStatusRequestDto { 
    eventId: string;
    state: DisputeEventChangeStatusRequestDto.StateEnum;
}
export namespace DisputeEventChangeStatusRequestDto {
    export type StateEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7;
    export const StateEnum = {
        NUMBER_1: 1 as StateEnum,
        NUMBER_2: 2 as StateEnum,
        NUMBER_3: 3 as StateEnum,
        NUMBER_4: 4 as StateEnum,
        NUMBER_5: 5 as StateEnum,
        NUMBER_6: 6 as StateEnum,
        NUMBER_7: 7 as StateEnum
    };
}


