/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProjectStatusEntity } from './projectStatusEntity';
import { ProjectDataEntity } from './projectDataEntity';


export interface ProjectEntity { 
    _id: string;
    code?: string;
    status: ProjectStatusEntity;
    type: ProjectEntity.TypeEnum;
    data: ProjectDataEntity;
    channel?: number;
}
export namespace ProjectEntity {
    export type TypeEnum = 'vendita' | 'acquisto';
    export const TypeEnum = {
        Vendita: 'vendita' as TypeEnum,
        Acquisto: 'acquisto' as TypeEnum
    };
}


