/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface GetFreeAgentsByDateRequestDto { 
    projectId: string;
    start: string;
    end: string;
    duration: number;
    medium: GetFreeAgentsByDateRequestDto.MediumEnum;
}
export namespace GetFreeAgentsByDateRequestDto {
    export type MediumEnum = 1 | 2 | 3;
    export const MediumEnum = {
        NUMBER_1: 1 as MediumEnum,
        NUMBER_2: 2 as MediumEnum,
        NUMBER_3: 3 as MediumEnum
    };
}


