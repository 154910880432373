/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PublishDataEntity { 
    portal: PublishDataEntity.PortalEnum;
    extId?: string;
    status: number;
    publishDate: string;
    description: string;
    title: string;
    titleComponents: Array<string>;
}
export namespace PublishDataEntity {
    export type PortalEnum = 'Facile immobiliare web' | 'Immobiliare.it' | 'Idealista';
    export const PortalEnum = {
        FacileImmobiliareWeb: 'Facile immobiliare web' as PortalEnum,
        ImmobiliareIt: 'Immobiliare.it' as PortalEnum,
        Idealista: 'Idealista' as PortalEnum
    };
}


