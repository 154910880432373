/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventRangeDto } from './eventRangeDto';
import { EventEntity } from './eventEntity';
import { UserEmbeddedEntity } from './userEmbeddedEntity';
import { AddressEntity } from './addressEntity';


export interface CreateFutureEventRequestDto { 
    event?: EventEntity;
    agent: string;
    agentObj?: UserEmbeddedEntity;
    project?: string;
    address: AddressEntity;
    type: CreateFutureEventRequestDto.TypeEnum;
    contact: string;
    medium?: number;
    note?: string;
    state?: number;
    attachments?: Array<string>;
    connectionInfo?: string;
    notVisible?: boolean;
    connectedProject?: string;
    isStar?: boolean;
    eventRange?: EventRangeDto;
    duration?: number;
    isExchange?: boolean;
    source?: number;
}
export namespace CreateFutureEventRequestDto {
    export type TypeEnum = 17 | 1 | 2 | 9 | 21 | 22 | 13 | 23;
    export const TypeEnum = {
        NUMBER_17: 17 as TypeEnum,
        NUMBER_1: 1 as TypeEnum,
        NUMBER_2: 2 as TypeEnum,
        NUMBER_9: 9 as TypeEnum,
        NUMBER_21: 21 as TypeEnum,
        NUMBER_22: 22 as TypeEnum,
        NUMBER_13: 13 as TypeEnum,
        NUMBER_23: 23 as TypeEnum
    };
}


