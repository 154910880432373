/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PraticaOkDocumentoApiDTO } from './praticaOkDocumentoApiDTO';
import { ChatPraticaOkDTO } from './chatPraticaOkDTO';


export interface PraticaApiDTO { 
    id?: number;
    numerazione?: string;
    indirizzo?: string;
    citta?: string;
    provincia?: string;
    cap?: string;
    scala?: string;
    piano?: string;
    amministratore?: string;
    telefonoAmministratore?: string;
    emailAmministratore?: string;
    stato?: PraticaApiDTO.StatoEnum;
    dataCreazione?: string;
    dataModifica?: string;
    dataScadenzaApe?: string;
    sopraluogo?: boolean;
    dataSopraluogo?: boolean;
    dataFermoPratica?: string;
    motivoFermoPratica?: string;
    codiceImmobileFIMM: string;
    societaId?: number;
    societa?: object;
    tecnicoMail?: string;
    AnagraficaUtenteApi?: object;
    consulenteMail?: string;
    documenti?: Array<PraticaOkDocumentoApiDTO>;
    messaggi?: Array<ChatPraticaOkDTO>;
}
export namespace PraticaApiDTO {
    export type StatoEnum = 'In lavorazione' | 'Conclusa' | 'Rogitata' | 'Ferma' | 'Nuova' | 'Annullata';
    export const StatoEnum = {
        InLavorazione: 'In lavorazione' as StatoEnum,
        Conclusa: 'Conclusa' as StatoEnum,
        Rogitata: 'Rogitata' as StatoEnum,
        Ferma: 'Ferma' as StatoEnum,
        Nuova: 'Nuova' as StatoEnum,
        Annullata: 'Annullata' as StatoEnum
    };
}


