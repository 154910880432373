/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PraticaOkDocumentoApiDTO { 
    id: number;
    filename: string;
    dataCaricamento: string;
    tipoDocumento: PraticaOkDocumentoApiDTO.TipoDocumentoEnum;
    personaId: number;
    praticaId: number;
    dataCaricamentoUTC: string;
}
export namespace PraticaOkDocumentoApiDTO {
    export type TipoDocumentoEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39 | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49 | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 | 60 | 61 | 62 | 63 | 64 | 65 | 66 | 67 | 68 | 69 | 70 | 71 | 72 | 73 | 74 | 75 | 76 | 77;
    export const TipoDocumentoEnum = {
        NUMBER_1: 1 as TipoDocumentoEnum,
        NUMBER_2: 2 as TipoDocumentoEnum,
        NUMBER_3: 3 as TipoDocumentoEnum,
        NUMBER_4: 4 as TipoDocumentoEnum,
        NUMBER_5: 5 as TipoDocumentoEnum,
        NUMBER_6: 6 as TipoDocumentoEnum,
        NUMBER_7: 7 as TipoDocumentoEnum,
        NUMBER_8: 8 as TipoDocumentoEnum,
        NUMBER_9: 9 as TipoDocumentoEnum,
        NUMBER_10: 10 as TipoDocumentoEnum,
        NUMBER_11: 11 as TipoDocumentoEnum,
        NUMBER_12: 12 as TipoDocumentoEnum,
        NUMBER_13: 13 as TipoDocumentoEnum,
        NUMBER_14: 14 as TipoDocumentoEnum,
        NUMBER_15: 15 as TipoDocumentoEnum,
        NUMBER_16: 16 as TipoDocumentoEnum,
        NUMBER_17: 17 as TipoDocumentoEnum,
        NUMBER_18: 18 as TipoDocumentoEnum,
        NUMBER_19: 19 as TipoDocumentoEnum,
        NUMBER_20: 20 as TipoDocumentoEnum,
        NUMBER_21: 21 as TipoDocumentoEnum,
        NUMBER_22: 22 as TipoDocumentoEnum,
        NUMBER_23: 23 as TipoDocumentoEnum,
        NUMBER_24: 24 as TipoDocumentoEnum,
        NUMBER_25: 25 as TipoDocumentoEnum,
        NUMBER_26: 26 as TipoDocumentoEnum,
        NUMBER_27: 27 as TipoDocumentoEnum,
        NUMBER_28: 28 as TipoDocumentoEnum,
        NUMBER_29: 29 as TipoDocumentoEnum,
        NUMBER_30: 30 as TipoDocumentoEnum,
        NUMBER_31: 31 as TipoDocumentoEnum,
        NUMBER_32: 32 as TipoDocumentoEnum,
        NUMBER_33: 33 as TipoDocumentoEnum,
        NUMBER_34: 34 as TipoDocumentoEnum,
        NUMBER_35: 35 as TipoDocumentoEnum,
        NUMBER_36: 36 as TipoDocumentoEnum,
        NUMBER_37: 37 as TipoDocumentoEnum,
        NUMBER_38: 38 as TipoDocumentoEnum,
        NUMBER_39: 39 as TipoDocumentoEnum,
        NUMBER_40: 40 as TipoDocumentoEnum,
        NUMBER_41: 41 as TipoDocumentoEnum,
        NUMBER_42: 42 as TipoDocumentoEnum,
        NUMBER_43: 43 as TipoDocumentoEnum,
        NUMBER_44: 44 as TipoDocumentoEnum,
        NUMBER_45: 45 as TipoDocumentoEnum,
        NUMBER_46: 46 as TipoDocumentoEnum,
        NUMBER_47: 47 as TipoDocumentoEnum,
        NUMBER_48: 48 as TipoDocumentoEnum,
        NUMBER_49: 49 as TipoDocumentoEnum,
        NUMBER_50: 50 as TipoDocumentoEnum,
        NUMBER_51: 51 as TipoDocumentoEnum,
        NUMBER_52: 52 as TipoDocumentoEnum,
        NUMBER_53: 53 as TipoDocumentoEnum,
        NUMBER_54: 54 as TipoDocumentoEnum,
        NUMBER_55: 55 as TipoDocumentoEnum,
        NUMBER_56: 56 as TipoDocumentoEnum,
        NUMBER_57: 57 as TipoDocumentoEnum,
        NUMBER_58: 58 as TipoDocumentoEnum,
        NUMBER_59: 59 as TipoDocumentoEnum,
        NUMBER_60: 60 as TipoDocumentoEnum,
        NUMBER_61: 61 as TipoDocumentoEnum,
        NUMBER_62: 62 as TipoDocumentoEnum,
        NUMBER_63: 63 as TipoDocumentoEnum,
        NUMBER_64: 64 as TipoDocumentoEnum,
        NUMBER_65: 65 as TipoDocumentoEnum,
        NUMBER_66: 66 as TipoDocumentoEnum,
        NUMBER_67: 67 as TipoDocumentoEnum,
        NUMBER_68: 68 as TipoDocumentoEnum,
        NUMBER_69: 69 as TipoDocumentoEnum,
        NUMBER_70: 70 as TipoDocumentoEnum,
        NUMBER_71: 71 as TipoDocumentoEnum,
        NUMBER_72: 72 as TipoDocumentoEnum,
        NUMBER_73: 73 as TipoDocumentoEnum,
        NUMBER_74: 74 as TipoDocumentoEnum,
        NUMBER_75: 75 as TipoDocumentoEnum,
        NUMBER_76: 76 as TipoDocumentoEnum,
        NUMBER_77: 77 as TipoDocumentoEnum
    };
}


