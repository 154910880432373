/**
 * Fimm API
 * Facile immobiliare API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CheckExistingContactLeadRequestDto { 
    contactId: string;
    projectType: CheckExistingContactLeadRequestDto.ProjectTypeEnum;
}
export namespace CheckExistingContactLeadRequestDto {
    export type ProjectTypeEnum = 'vendita' | 'acquisto';
    export const ProjectTypeEnum = {
        Vendita: 'vendita' as ProjectTypeEnum,
        Acquisto: 'acquisto' as ProjectTypeEnum
    };
}


